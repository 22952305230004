import * as React from 'react'
import Layout from '../components/layout'
import ContactCard from '../components/contactCard'
import { Heading, Text, Container, useBreakpointValue } from '@chakra-ui/react'

const Contact = () => {
  return (
    <Layout title="Elitehome - Kapcsolat">
      <div style={{ minHeight: '35vw' }}>
        <Container style={{ textAlign: 'center', padding: '50px 0 10px 0' }}>
          <Heading as="h1" fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: useBreakpointValue({ base: '20%', md: '30%' }),
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'red.400',
                zIndex: -1,
              }}
            >
              Kapcsolat
            </Text>
          </Heading>
        </Container>
        <ContactCard />
      </div>
    </Layout>
  )
}

export default Contact
